<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" class="blue--text"><slot></slot></span>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ user.userName }}
      </v-card-title>

      <v-card-text>
        {{ user.aboutInfo }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Закрыть </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "showPsyhDescriptionModal",
  data() {
    return {
      dialog: false,
    };
  },
  props: ["user"],
};
</script>

<style scoped></style>
